import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import * as Constants from '../../../utils/constants'
import NothingToSee from '../Messages/NothingToSee'
import TodayItems from './TodayItems'
import FailedToFetch from '../ErrorPages/FailedToFetch'

export const Content = ({
  hasNoActivities,
  hasError,
  items,
  section,
  isMobile
}) => {
  let heading
  switch (section && section.toLowerCase()) {
    case Constants.GOLTASKS_ROUTE:
      heading = 'GOL Tasks'
      break
    default:
      heading = 'Activities'
  }

  return (
    <GridWrapper horizontalAlign="center" id="activities-content-container">
      <GridItem
        size="1/1"
        className="ln-u-margin-top*3"
        style={{ padding: !isMobile && 0 }}
      >
        <TodayItems items={items} isMobile={isMobile} heading={heading} />
        {hasError && <FailedToFetch />}
        {hasNoActivities && <NothingToSee />}
      </GridItem>
    </GridWrapper>
  )
}

Content.propTypes = {
  hasNoActivities: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}

Content.defaultProps = {
  hasNoActivities: true
}

export default Content
