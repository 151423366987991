import React from 'react'
import PropTypes from 'prop-types'
import { StatusCard } from '@jsluna/card'
import { FlagWrapper, FlagBody } from '@jsluna/grid'
import { Info } from '@jsluna/icons'
import {
  VIEW_ACTIVITY_ROUTE,
  ACTIVITY_TYPE_INFO,
  GROUPED_ITEM_TYPE
} from 'utils/constants'
import GroupedItemHolder from './GroupedItemHolder'

const ItemHolder = ({ item, history }) => {
  const outputElements = []
  const className = `c-activity-item ${item.isUrgent ? 'urgent' : 'standard'} ${
    item.duration > 1 ? `x${item.duration}` : ''
  } ${
    item.isCompleted ||
    item.isMissed ||
    (item.isPast && item.type === ACTIVITY_TYPE_INFO)
      ? 'c-activity-past'
      : 'c-activity-current'
  }`

  outputElements.push(
    item.type === GROUPED_ITEM_TYPE ? (
      <GroupedItemHolder className={className} item={item} history={history} />
    ) : (
      <a
        role="presentation"
        style={{ cursor: 'pointer' }}
        onClick={
          history
            ? () => history.push(`${VIEW_ACTIVITY_ROUTE}/${item.id}`)
            : () => {}
        }
      >
        <StatusCard
          className={className}
          status={item.isUrgent ? 'danger' : 'none'}
        >
          <FlagWrapper>
            <FlagBody>
              <div
                className="ln-u-text-align-left"
                style={{
                  paddingLeft: '0.5rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                <b
                  className="activity-card-text"
                  style={{
                    marginBottom: '0rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {item.type === ACTIVITY_TYPE_INFO && (
                    <Info className="ln-u-margin-right" />
                  )}
                  {item.title}
                </b>
              </div>
            </FlagBody>
          </FlagWrapper>
        </StatusCard>
      </a>
    )
  )

  return (
    <div>
      {outputElements.map(element => (
        <div key={`${item.id}-holder`}> {element} </div>
      ))}
    </div>
  )
}

ItemHolder.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    duration: PropTypes.number,
    position: PropTypes.number,
    title: PropTypes.string,
    dueDate: PropTypes.string,
    spaceAbove: PropTypes.number,
    attributes: PropTypes.array,
    isCompleted: PropTypes.bool,
    isMissed: PropTypes.bool,
    isPast: PropTypes.bool,
    isUrgent: PropTypes.bool,
    items: PropTypes.array
  }).isRequired,
  history: PropTypes.object
}

ItemHolder.defaultProps = {
  history: null
}

export default ItemHolder
