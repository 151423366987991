import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Header as Head,
  HeaderGlobalBar,
  HeaderGlobalItem,
  HeaderMainBar,
  HeaderNav,
  HeaderLogo,
  HeaderSearch
} from '@jsluna/header'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { v1 } from 'uuid'
import {
  SEARCH,
  LOGOUT,
  CHANGE_STORE,
  ROUTE_HISTORY,
  ROUTE_TODAY,
  ROUTE_RISK,
  ROUTE_GOLTASKS,
  ROUTE_PLANNING,
  ROUTE_SEARCH,
  ROUTE_RISK_COMPLIANCE,
  DISTRIBUTION_CENTRES_GROUP,
  RISK_TRIAL_GROUP_LIST
} from 'utils/constants'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import { OpenButton } from '@jsainsburyplc/retail-stateful-tutorial'
import { getColleagueSettingsFromStorage } from 'utils/currentStore'
import { setFilters } from '../../utils/browserStorage'

const getAdditionalItems = (
  currentUser,
  currentStore,
  mobileView,
  sendGAEvent
) => {
  const items = []
  const settings = getColleagueSettingsFromStorage()
  const inStore =
    currentStore && currentStore.code
      ? ` in ${
          window.innerWidth <= 768 && currentStore.name.length > 30
            ? `${currentStore.name.substr(0, 30)}...`
            : currentStore.name
        } (${currentStore.code})`
      : ''

  items.push(
    <HeaderGlobalItem
      key={v1()}
      align="right"
      className={mobileView ? 'header-mobile-item' : ''}
    >
      <Link
        to="#userinfo"
        className={`headerCurrentUser ${
          !mobileView ? 'header__global-item' : ''
        }`}
        onClick={e => e.preventDefault()}
      >
        {currentUser} {inStore}
      </Link>
    </HeaderGlobalItem>
  )

  items.push(
    <HeaderGlobalItem
      key={v1()}
      className={mobileView ? 'header-mobile-item' : ''}
    >
      <a
        className={!mobileView ? 'ln-u-text-decoration-underline' : ''}
        href={`${window.location.origin}/safeandlegal/overview`}
        onClick={() => sendGAEvent('link_regional_compliance')}
      >
        Regional compliance
      </a>
    </HeaderGlobalItem>
  )

  items.push(
    <HeaderGlobalItem
      key={v1()}
      className={`header__global-item ${
        mobileView ? 'header-mobile-item ' : ''
      }`}
    >
      <OpenButton>
        {showTutorial => (
          <NavLink
            to="#"
            className={!mobileView ? 'header__whats-new' : ''}
            name="Tutorial"
            onClick={() => showTutorial()}
          >
            What&apos;s New
          </NavLink>
        )}
      </OpenButton>
    </HeaderGlobalItem>
  )
  if (settings) {
    items.push(
      <HeaderGlobalItem
        key={v1()}
        className={mobileView ? 'header-mobile-item' : ''}
      >
        <Link
          className={!mobileView ? 'header__global-item' : ''}
          to={CHANGE_STORE}
        >
          Change store
        </Link>
      </HeaderGlobalItem>
    )
  }

  items.push(
    <HeaderGlobalItem
      key={v1()}
      className={mobileView ? 'header-mobile-item' : ''}
    >
      <Link
        className={!mobileView ? 'header__global-item' : ''}
        to={LOGOUT}
        onClick={() => setFilters({ filters: [] })}
      >
        Logout
      </Link>
    </HeaderGlobalItem>
  )

  return items
}

const getRiskTab = (canAccessRisk, groups, changeActiveTab, path) => {
  if (
    canAccessRisk &&
    groups &&
    groups.some(group => RISK_TRIAL_GROUP_LIST.includes(group))
  ) {
    return (
      <NavLink
        to={ROUTE_RISK}
        onClick={() => changeActiveTab('risk')}
        active={path === ROUTE_RISK}
      >
        Risk
      </NavLink>
    )
  }
  return <div className="ln-u-display-none" />
}

const getTrialStoreRiskComplianceLink = (groups, changeActiveTab, path) => {
  if (groups && groups.some(group => RISK_TRIAL_GROUP_LIST.includes(group))) {
    return (
      <NavLink
        to={ROUTE_RISK_COMPLIANCE}
        onClick={() => changeActiveTab('risk-compliance')}
        active={path === ROUTE_RISK_COMPLIANCE}
      >
        Compliance
      </NavLink>
    )
  }
  return <div />
}

const getPlanningTab = (groups, changeActiveTab, path) => {
  if (groups && !groups.includes(DISTRIBUTION_CENTRES_GROUP)) {
    return (
      <NavLink
        to={ROUTE_PLANNING}
        onClick={() => changeActiveTab('planning')}
        active={path === ROUTE_PLANNING}
      >
        Planning
      </NavLink>
    )
  }

  return <div className="ln-u-display-none" />
}

const getTodayTab = (groups, changeActiveTab, path) => {
  if (groups && !groups.includes(DISTRIBUTION_CENTRES_GROUP)) {
    return (
      <NavLink
        to={ROUTE_TODAY}
        onClick={() => changeActiveTab('activities')}
        active={path === ROUTE_TODAY}
      >
        Today
      </NavLink>
    )
  }

  return <div className="ln-u-display-none" />
}

const getGOLTasksTab = (groups, changeActiveTab, path) => {
  if (groups && !groups.includes(DISTRIBUTION_CENTRES_GROUP)) {
    return (
      <NavLink
        to={ROUTE_GOLTASKS}
        onClick={() => changeActiveTab('goltasks')}
        active={path === ROUTE_GOLTASKS}
      >
        GOL Tasks
      </NavLink>
    )
  }

  return <div className="ln-u-display-none" />
}

const Header = ({
  currentUser,
  currentStore,
  changeActiveTab,
  getStoreGroups,
  storeGroups,
  history,
  path,
  canAccessRisk
}) => {
  const [searchCriteria, setSearchCriteria] = useState('')

  const { sendEvent } = useAnalytics()

  const sendGAEvent = event => {
    sendEvent(event)
  }

  useEffect(() => {
    if (!(!currentStore || !currentStore.code)) {
      getStoreGroups(currentStore.code)
    }
  }, [currentStore])

  useEffect(() => {
    if (
      storeGroups &&
      storeGroups.includes(DISTRIBUTION_CENTRES_GROUP) &&
      path.includes(ROUTE_TODAY)
    ) {
      history.push(ROUTE_RISK)
    }
  }, [path, storeGroups])

  useEffect(() => {
    if (!path.includes(ROUTE_SEARCH)) {
      setSearchCriteria('')
    }
  }, [path])

  return (
    <Head divided hasGlobalBar>
      <HeaderGlobalBar label="Group Navigation">
        {getAdditionalItems(currentUser, currentStore, false, sendGAEvent)}
      </HeaderGlobalBar>
      <HeaderMainBar>
        <HeaderLogo>Activity</HeaderLogo>
        {storeGroups && (
          <HeaderNav drawer openDropdownOnHover label="Main">
            {getTodayTab(storeGroups, changeActiveTab, path)}
            {getRiskTab(canAccessRisk, storeGroups, changeActiveTab, path)}
            {getGOLTasksTab(storeGroups, changeActiveTab, path)}
            {getPlanningTab(storeGroups, changeActiveTab, path)}
            <NavLink
              to={ROUTE_HISTORY}
              onClick={() => changeActiveTab('history')}
              active={path === ROUTE_HISTORY}
            >
              History
            </NavLink>
            {getTrialStoreRiskComplianceLink(
              storeGroups,
              changeActiveTab,
              path
            )}
            <span className="header-mobile-view" />
            {getAdditionalItems(currentUser, currentStore, true, sendGAEvent)}
          </HeaderNav>
        )}
        <HeaderSearch
          align="right"
          onSubmit={e => {
            e.preventDefault()
            if (searchCriteria.length > 0) {
              history.push(`${SEARCH}/${searchCriteria}`, {
                from: history.location.pathname
              })
            }
          }}
          field={{
            onChange: e => {
              setSearchCriteria(e.target.value)
            },
            hasButton: true,
            value: searchCriteria
          }}
          tabBar="max-nav"
          tabBarSoft
        />
      </HeaderMainBar>
    </Head>
  )
}

Header.propTypes = {
  currentUser: PropTypes.string.isRequired,
  currentStore: PropTypes.object,
  changeActiveTab: PropTypes.func,
  getStoreGroups: PropTypes.func.isRequired,
  storeGroups: PropTypes.array,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  canAccessRisk: PropTypes.bool
}

Header.defaultProps = {
  currentStore: {
    code: null
  },
  changeActiveTab: undefined,
  storeGroups: null,
  canAccessRisk: false
}

export default withRouter(Header)
