import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  buildCalendar,
  buildSchedule,
  getSelectedDayFromPeriodWeeks
} from '../../../../utils/Calendar/planning'
import PeriodViewDayHolder from '../Calendar/PeriodViewDayHolder'
import WeekGroupedItems from './WeekGroupedItems'
import DayGroupedItems from './DayGroupedItems'
import { CALENDAR_TYPES } from '../../../../utils/constants'

const PeriodView = ({
  periodActivities,
  history,
  isMobile,
  selectedDay,
  isPanelOpen,
  showAllCalendarItems
}) => {
  const [weekWithSingleDayActivities, setWeekWithSingleDayActivities] =
    useState(null)
  const [weekWithMultiDayActivities, setWeekWithMultiDayActivities] =
    useState(null)
  const [schedule, setSchedule] = useState(null)
  useEffect(() => {
    buildCalendar(
      moment(periodActivities.periodStartDate).startOf('day'),
      periodActivities.items.filter(x => x.matchesFilter && x.duration === 1),
      CALENDAR_TYPES.period,
      showAllCalendarItems
    ).then(res => setWeekWithSingleDayActivities(res))

    buildCalendar(
      moment(periodActivities.periodStartDate).startOf('day'),
      periodActivities.items.filter(x => x.matchesFilter && x.duration !== 1),
      CALENDAR_TYPES.period,
      showAllCalendarItems
    ).then(res => setWeekWithMultiDayActivities(res))

    buildSchedule(
      moment(periodActivities.periodStartDate).startOf('day'),
      periodActivities.items.filter(x => x.matchesFilter),
      CALENDAR_TYPES.period
    ).then(res => setSchedule(res))
  }, [periodActivities, showAllCalendarItems])

  const periodElements = []
  const periodElementsExists =
    weekWithSingleDayActivities?.some(activity => activity.items?.length > 0) &&
    weekWithMultiDayActivities?.some(activity => activity.items?.length > 0)

  if (weekWithSingleDayActivities && weekWithMultiDayActivities) {
    for (let weekIndex = 0; weekIndex < 4; weekIndex += 1) {
      periodElements.push(
        <>
          <div
            className={`ln-u-bg-color-white ln-u-soft-bottom ${
              periodElementsExists ? '' : 'c-planning-view-padding-bottom-0'
            }`}
          >
            <div
              className={`c-planning-view${
                isPanelOpen ? '-condensed' : ''
              } period ${
                periodElementsExists ? '' : 'c-planning-view-padding-top-0'
              }`}
              key={weekWithSingleDayActivities[weekIndex][0].date.format()}
            >
              {weekWithSingleDayActivities[weekIndex].map(day => (
                <PeriodViewDayHolder
                  history={history}
                  key={day.date.format('ddd D')}
                  day={day}
                  includeHeader={weekIndex === 0}
                  showAllCalendarItems={showAllCalendarItems}
                />
              ))}
            </div>
          </div>
          <div className="ln-u-bg-color-white ln-u-soft-bottom">
            <div
              className={`c-planning-view${
                isPanelOpen ? '-condensed' : ''
              } period ${
                periodElementsExists ? '' : 'c-planning-view-padding-top-0'
              }`}
              key={weekWithMultiDayActivities[weekIndex][0].date.format()}
            >
              {weekWithMultiDayActivities[weekIndex].map(day => (
                <PeriodViewDayHolder
                  history={history}
                  key={day.date.format('ddd D')}
                  day={day}
                  includeHeader={false}
                  includeDates={false}
                  showAllCalendarItems={showAllCalendarItems}
                />
              ))}
            </div>
          </div>
        </>
      )
    }
  }

  return (
    weekWithSingleDayActivities != null &&
    weekWithMultiDayActivities != null &&
    schedule !== null && (
      <div>
        {!isMobile ? (
          <div>
            {periodElements}
            <WeekGroupedItems weeks={schedule} />
          </div>
        ) : (
          <>
            <h2>Schedule</h2>
            <DayGroupedItems
              day={getSelectedDayFromPeriodWeeks(schedule, selectedDay.date)}
            />
          </>
        )}
      </div>
    )
  )
}

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile,
  selectedDay: state.activities.selectedCalendarDay,
  isPanelOpen: !state.activities.isMobile || state.filter.isPanelOpen,
  showAllCalendarItems: state.activities.showAllCalendarItems
})

PeriodView.propTypes = {
  periodActivities: PropTypes.shape({
    items: PropTypes.array,
    periodStartDate: PropTypes.string.isRequired
  }),
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedDay: PropTypes.object.isRequired,
  isPanelOpen: PropTypes.bool,
  showAllCalendarItems: PropTypes.bool
}

PeriodView.defaultProps = {
  periodActivities: {
    items: []
  },
  isPanelOpen: false,
  showAllCalendarItems: false
}

export default connect(mapStateToProps)(PeriodView)
