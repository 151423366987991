import React from 'react'
import { GridItem } from '@jsluna/grid'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import * as Constants from 'utils/constants'
import {
  toggleFilterModal,
  toggleNewActionModal,
  createActionRequest
} from 'actions'
import ItemHeader from './ItemHeader'
import TodoItem from './TodoItem/TodoItem'
import ActivityCard from '../Shared/ActivityCard/ActivityCard'
import SkeletonItem from './SkeletonItem'
import FilterModal from './FilterModal'
import AdhocActionModal from '../ViewActivity/Processes/Audit/AdhocActionModal'

export const TodayItems = ({
  items,
  isFetchingItems,
  isMobile,
  currentUser,
  showNewActionModal,
  closeNewActionModal,
  submitAction,
  heading
}) => {
  const communicationItems = items.filter(
    item => item.isCommunication && !item.isUrgent
  )
  const skeletonItems = () => {
    const loopedItems = []
    for (let i = 0; i < 3; i += 1) {
      loopedItems.push(<SkeletonItem key={i} />)
    }
    return loopedItems
  }
  const isTodoItem = item =>
    (item.type === Constants.ACTIVITY_TYPE_TODO ||
      item.type === Constants.ACTIVITY_TYPE_SURVEY ||
      item.type === Constants.ACTIVITY_TYPE_AUDIT ||
      item.type === Constants.ACTIVITY_TYPE_ACTION) &&
    !item.isCommunication

  const assignedHighPriorityItems = []
  const otherHighPriorityItems = []
  const assignedoOverdueTodos = []
  const otherOverdueTodos = []
  const assignedTodos = []
  const otherTodos = []

  const activityGrouper = item => {
    if (isTodoItem(item) || item.isCommunication) {
      if (item.isUrgent && item.colleagueAssignmentSummary === currentUser) {
        assignedHighPriorityItems.push(item)
      }
      if (
        item.isUrgent &&
        (!item.colleagueAssignmentSummary ||
          item.colleagueAssignmentSummary !== currentUser)
      ) {
        otherHighPriorityItems.push(item)
      }
      if (
        !item.isUrgent &&
        item.isOverdue &&
        !item.isCommunication &&
        item.colleagueAssignmentSummary === currentUser
      ) {
        assignedoOverdueTodos.push(item)
      }
      if (
        !item.isUrgent &&
        item.isOverdue &&
        !item.isCommunication &&
        (!item.colleagueAssignmentSummary ||
          item.colleagueAssignmentSummary !== currentUser)
      ) {
        otherOverdueTodos.push(item)
      }
      if (
        !item.isUrgent &&
        !item.isOverdue &&
        !item.isCommunication &&
        item.colleagueAssignmentSummary === currentUser
      ) {
        assignedTodos.push(item)
      }
      if (
        !item.isUrgent &&
        !item.isOverdue &&
        !item.isCommunication &&
        (!item.colleagueAssignmentSummary ||
          item.colleagueAssignmentSummary !== currentUser)
      ) {
        otherTodos.push(item)
      }
    }
  }

  items.map(item => activityGrouper(item))

  return (
    <div style={{ marginTop: '-30px' }}>
      <AdhocActionModal
        isOpen={showNewActionModal}
        closeNewActionModal={closeNewActionModal}
        submitAction={submitAction}
      />
      <FilterModal />
      <h2 id="section-heading">Today</h2>
      <div
        style={{
          display: 'inline-flex',
          width: '100%'
        }}
      />
      {isFetchingItems && items.length === 0 ? (
        <div
          className={`ln-u-push-bottom-lg ${
            !isMobile ? 'ln-u-margin-left*2' : ''
          }`}
        >
          <ItemHeader />
          {skeletonItems()}
        </div>
      ) : (
        <>
          {(assignedHighPriorityItems.length > 0 ||
            otherHighPriorityItems.length > 0) && (
            <div id="high-priority-items" className="ln-u-push-bottom-lg">
              {assignedHighPriorityItems.length > 0 && (
                <div
                  id="assigned-high-priority-items"
                  className="ln-u-soft-bottom"
                >
                  {assignedHighPriorityItems.map(item => (
                    <GridItem
                      key={item.id}
                      size="1/1"
                      className={`${isMobile && 'ln-u-hard-left'}`}
                    >
                      <TodoItem item={item} key={`${item.id}-${v4()}`} />
                    </GridItem>
                  ))}
                </div>
              )}
              <div id="non-assigned-high-priority-items">
                {otherHighPriorityItems
                  .sort((a, b) => a.isOverdue - b.isOverdue)
                  .map(item => (
                    <GridItem
                      key={item.id}
                      size="1/1"
                      className={`${isMobile && 'ln-u-hard-left'}`}
                    >
                      <TodoItem item={item} key={`${item.id}-${v4()}`} />
                    </GridItem>
                  ))}
              </div>
            </div>
          )}
          {(assignedoOverdueTodos.length > 0 ||
            otherOverdueTodos.length > 0 ||
            assignedTodos.length > 0 ||
            otherTodos.length > 0) && (
            <div id="today-items" className="ln-u-push-bottom-lg">
              <ItemHeader title={heading} />
              {assignedoOverdueTodos.length > 0 && (
                <div id="assigned-overdue-items" className="ln-u-soft-bottom">
                  {assignedoOverdueTodos.map(item => (
                    <GridItem
                      key={item.id}
                      size="1/1"
                      className={`${isMobile && 'ln-u-hard-left'}`}
                    >
                      <TodoItem item={item} key={`${item.id}-${v4()}`} />
                    </GridItem>
                  ))}
                </div>
              )}
              {otherOverdueTodos.length > 0 && (
                <div
                  id="non-assigned-overdue-items"
                  className="ln-u-soft-bottom"
                >
                  {otherOverdueTodos.map(item => (
                    <GridItem
                      key={item.id}
                      size="1/1"
                      className={`${isMobile && 'ln-u-hard-left'}`}
                    >
                      <TodoItem item={item} key={`${item.id}-${v4()}`} />
                    </GridItem>
                  ))}
                </div>
              )}
              {assignedTodos.length > 0 && (
                <div id="assigned-todo-items" className="ln-u-soft-bottom">
                  {assignedTodos.map(item => (
                    <GridItem
                      key={item.id}
                      size="1/1"
                      className={`${isMobile && 'ln-u-hard-left'}`}
                    >
                      <TodoItem item={item} key={`${item.id}-${v4()}`} />
                    </GridItem>
                  ))}
                </div>
              )}
              <div id="non-assigned-todo-items">
                {otherTodos.map(item => (
                  <GridItem
                    key={item.id}
                    size="1/1"
                    className={`${isMobile && 'ln-u-hard-left'}`}
                  >
                    <TodoItem item={item} key={`${item.id}-${v4()}`} />
                  </GridItem>
                ))}
              </div>
            </div>
          )}
          {communicationItems.length > 0 && (
            <div id="need-to-know-items">
              <ItemHeader title="Communications" />
              {communicationItems.map(item => (
                <GridItem
                  key={item.id}
                  size="1/1"
                  className={`${isMobile && 'ln-u-hard-left'}`}
                >
                  <ActivityCard item={item} key={`${item.id}-${v4()}`} />
                </GridItem>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  showFilterModal: () => dispatch(toggleFilterModal(true)),
  closeNewActionModal: () => dispatch(toggleNewActionModal(false)),
  submitAction: request => dispatch(createActionRequest(request))
})

const mapStateToProps = state => ({
  currentUser: state.azure.currentUser,
  isFetchingItems: state.activities.isFetching,
  showNewActionModal: state.activities.showNewActionModal
})

TodayItems.propTypes = {
  items: PropTypes.array.isRequired,
  isFetchingItems: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  currentUser: PropTypes.string,
  showNewActionModal: PropTypes.bool.isRequired,
  closeNewActionModal: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired
}

TodayItems.defaultProps = {
  isFetchingItems: true,
  currentUser: ''
}

export default connect(mapStateToProps, mapDispatchToProps)(TodayItems)
