import * as Constants from './constants'

const Routes = [
  {
    route: Constants.TODAY_ROUTE,
    text: Constants.TODAY
  },
  {
    route: Constants.PLANNING_ROUTE,
    text: Constants.PLANNING
  },
  {
    route: Constants.GOLTASKS_ROUTE,
    text: Constants.GOLTASKS
  }
]

export default Routes
