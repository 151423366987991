import React from 'react'
import FilterLayout from '../Filtering/FilterLayout'
import Activities from './Activities'

const GolActivitiesWithFilters = props => (
  <div>
    <FilterLayout header={<></>}>
      <Activities {...props} />
    </FilterLayout>
  </div>
)

export default GolActivitiesWithFilters
